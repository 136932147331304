import React from "react"
import LayoutCourse from "../../../../../layouts/course"
import Seo from "../../../../../components/seo"
import Stack from "../../../../../components/stack"
import Note from "../../../../../components/note"
import CourseFooterNext from "../../../../../components/course-footer-next"
import {
  getCourseNavigation,
  useCourseStore,
} from "../../../../../store/courses"
import SyllablesEvaluation from "../../../../../components/syllables-evaluation"
import FeedbackHeaderProgress from "../../../../../components/feedback-header-progress"
import AudioPlayer from "../../../../../components/audio-player"
import Hyphen from "../../../../../components/hyphen"
import Text from "../../../../../components/text"

const Page = () => {
  const { getAnswer, getTask, getStatsForTaskWithDict } = useCourseStore()
  const navigation = getCourseNavigation({ courseId: "jahreszeiten" })
  const taskProps = {
    courseId: "jahreszeiten",
    chapterId: "01-jahreszeitengedicht",
    taskId: "silben",
  }
  const task = getTask(taskProps)
  const answer = getAnswer(taskProps)
  const statistics = getStatsForTaskWithDict(taskProps)

  const RenderAnswer = ({ id, children }) => {
    return (
      <SyllablesEvaluation
        answer={answer?.[id]}
        solution={task.solution[id]}
        key={`answer_${id}`}
      >
        {children}
      </SyllablesEvaluation>
    )
  }

  return (
    <LayoutCourse
      navigation={navigation}
      footer={
        <CourseFooterNext
          title="Alles klar soweit?"
          label="Weiter"
          to="/kurse/jahreszeiten/01-jahreszeitengedicht/lesen-und-einpraegen"
        />
      }
    >
      <Seo title="Hölderlin und die Jahreszeiten" />
      <Stack>
        <FeedbackHeaderProgress
          correct={statistics.correct}
          total={statistics.correct + statistics.incorrect + statistics.missed}
        />
        <Stack space="6">
          <RenderAnswer id="q-01">
            <strong>Der Früh-ling</strong>
          </RenderAnswer>
          <RenderAnswer id="q-02">
            <p>
              Wenn aus der Tie
              <Hyphen />
              fe kommt der Früh
              <Hyphen />
              ling in das Le
              <Hyphen />
              ben,
            </p>
          </RenderAnswer>
          <RenderAnswer id="q-03">
            <p>
              Es wun
              <Hyphen />
              dert sich der Mensch, und neu
              <Hyphen />e Wor
              <Hyphen />
              te stre
              <Hyphen />
              ben
            </p>
          </RenderAnswer>
          <RenderAnswer id="q-04">
            <p>
              Aus Gei
              <Hyphen />
              stig
              <Hyphen />
              keit, die Freu
              <Hyphen />
              de keh
              <Hyphen />
              ret wie
              <Hyphen />
              der
            </p>
          </RenderAnswer>
          <RenderAnswer id="q-05">
            <p>
              Und fest
              <Hyphen />
              lich ma
              <Hyphen />
              chen sich Ge
              <Hyphen />
              sang und Lie
              <Hyphen />
              der.
            </p>
          </RenderAnswer>
          <RenderAnswer id="q-06">
            <p>
              Das Le
              <Hyphen />
              ben fin
              <Hyphen />
              det sich aus Har
              <Hyphen />
              mo
              <Hyphen />
              nie der Zei
              <Hyphen />
              ten,
            </p>
          </RenderAnswer>
          <RenderAnswer id="q-07">
            <p>
              Daß im
              <Hyphen />
              mer
              <Hyphen />
              dar den Sinn Na
              <Hyphen />
              tur und Geist ge
              <Hyphen />
              lei
              <Hyphen />
              ten,
            </p>
          </RenderAnswer>
          <RenderAnswer id="q-08">
            <p>
              Und die Voll
              <Hyphen />
              kom
              <Hyphen />
              men
              <Hyphen />
              heit ist Ei
              <Hyphen />
              nes in dem Gei
              <Hyphen />
              ste,
            </p>
          </RenderAnswer>
          <RenderAnswer id="q-09">
            <p>
              So fin
              <Hyphen />
              det vie
              <Hyphen />
              les sich, und aus Na
              <Hyphen />
              tur das Mei
              <Hyphen />
              ste.
            </p>
          </RenderAnswer>
        </Stack>
        <Note title="Hör genau hin">
          <Text size={[2, 3]}>
            Aus der Anzahl der Silben ergibt sich ein Muster. Hölderlin muss
            beim Schreiben genau darauf geachtet haben, dass die Silbenzahl in
            jeder Zeile gleich ist. Dadurch wirken die Zeilen ziemlich ähnlich
            und gleichmäßig. Das fällt besonders auf, wenn man das Gedicht laut
            liest. Hör genau hin.
          </Text>
        </Note>
        <AudioPlayer src={`dauerausstellung/05-haelfte-des-lebens/fruehling`} />
      </Stack>
    </LayoutCourse>
  )
}

export default Page
